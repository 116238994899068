<template>
 <div :class="windowClass">
   <v-container>
     <v-toolbar flat>
       <div class="text-h4">EVENT ACCOUNT SUMMARY</div>
       <v-spacer/>
     
     </v-toolbar>
     <v-divider></v-divider>
    <v-row dense class="mt-10">
       <v-col cols="12">
         <v-col cols="12">
     <v-toolbar flat dense>
       <v-spacer/>
        <v-spacer/>
       <v-autocomplete 
        label="Select Event"
        v-model="event"
        :items="events"
        dense
        outlined
        item-text="name"
        return-object
        @change="getFights()"
       />
       
     </v-toolbar>
      </v-col>
      <v-toolbar class="mb-2" dense>EVENT NAME: {{event.name}}<v-spacer></v-spacer>DATE: {{event.event_date}}<v-spacer></v-spacer>
        <span style="width:200px;">
      <v-select
        :items="['Per Fight', 'Per Account']"
        v-model="toggle"
        outlined
        dense
        hide-details
      />
    </span>
      </v-toolbar>
      <!-- EVENT NAME: {{event.name}}<br />
      EVENT DATE: {{event.event_date}} -->
    <v-card v-if="toggle == 'Per Fight'">
      <v-toolbar dense> Account Per Fight</v-toolbar>
      <v-card-text>
       
       <table class="va-table md-font">
                <tr>
                  <td style="background: #313131; color: #fff" class="pa-1">POS</td>
                  <td style="background: #313131; color: #fff" class="pa-1">FIGHT #</td>
                  <td style="background: #313131; color: #fff" class="pa-1  text-right">SALES</td>
                </tr>
                <tr v-for="(item,index) in items" :key="index">
                    <td class="border_bot">
                      {{ item.agent }}
                    </td>
                    <td class="border_bot">
                      {{ item.fight_no }}
                    </td>
                    <td class="border_bot  text-right">
                      {{ $money(item.sales) }}
                    </td>
                </tr>
                <tr style="background: #eee;">
                  <th class="border_bot">
                   
                  </th>
                    <th class="border_bot text-right">
                      TOTAL:
                    </th>
                    <th class="border_bot  text-right">
                      {{ $money(TOTALSALES) }}
                    </th>
                </tr>
           </table>
          </v-card-text>
          </v-card>

          <v-card v-else>
      <v-toolbar dense> Per Account</v-toolbar>
      <v-card-text>
       <table class="va-table md-font">
                <tr>
                  <td style="background: #313131; color: #fff" class="pa-1">POS</td>
                  <td style="background: #313131; color: #fff" class="pa-1  text-right">SALES</td>
                  <td style="background: #313131; color: #fff" class="pa-1  text-right">CLAIMS</td>
                  <td style="background: #313131; color: #fff" class="pa-1  text-right">REFUNDS</td>
                  <td style="background: #313131; color: #fff" class="pa-1  text-right">COLLECTIBLES</td>
                </tr>
                <tr v-for="(item,index) in POS" :key="index">
                    <td class="border_bot">
                      {{ item.agent }}
                    </td>
                    <td class="border_bot text-right">
                      {{ $money(item.sales) }}
                    </td>
                    <td class="border_bot  text-right">
                      {{ $money(item.claimed) }}
                    </td>
                    <td class="border_bot  text-right">
                      {{ $money(item.refunds) }}
                    </td>
                    <th class="border_bot  text-right">
                      {{ $money($IsNum(item.sales) - $IsNum(item.claimed) - $IsNum(item.refunds)) }}
                    </th>
                </tr>
                <tr style="background: #eee;">
                  <th class="border_bot">
                   
                  </th>
                    <th class="border_bot text-right">
                      {{ $money(PERACCOUNTSALES) }}
                    </th>
                    <th class="border_bot  text-right">
                      {{ $money(PERACCOUNTCLAIMED) }}
                    </th>
                    <th class="border_bot  text-right">
                      {{ $money(PERACCOUNTREFUNDS) }} 
                    </th>
                    <th class="border_bot  text-right">
                      {{ $money(PERACCOUNTSALES- PERACCOUNTCLAIMED - PERACCOUNTREFUNDS) }}
                    </th>
                </tr>
           </table>
          </v-card-text>
          </v-card>
       </v-col>
    </v-row>
    </v-container>
      <!-- <va-fight-form :show="form" :state="state" :fight_count="fights.length" :entry="fight" @DialogEvent="formEvent"/>
      <va-fight-result-form :show="formform" :state="state" :entry="fight" @DialogEvent="formResEvent"/> -->
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        formform: false,
        state: 'new',
        toggle: 'Per Fight',
        fight:{},
        event_id:0,
        fights: [],
        events: [],
        event: {},
        items:[],
        claims:[],
        refunds:[],
        search:'',
        headers: [
            { text: 'Fight No.', value:"event_date"},
            { text: 'Meron', value:"meron", align:"left"},
            { text: 'Wala', value:"wala", align:"left"},
             { text: 'Meron Gross', value:"meron", align:"left"},
            { text: 'Wala Gross', value:"wala", align:"left"},
            { text: 'Status', value:"status"},
            { text: 'Result', value:"result", align: 'right'},
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
       // this.getItems()
        this.getEvents()
       // this.GET_BREEDERS()
        //this.GET_OWNERS()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      POS() {
      //  var itemdata = 
        var items = this.claims.reduce((res,item)=>{
          item.refunds = this.getRefunds(item.player_id)
          res.push(item)
          return res
        },[])
        return items
      },
      PERACCOUNTSALES(){
        var total = this.claims.reduce((res,item)=>res+=item.sales,0)
        return total
      },
      PERACCOUNTREFUNDS(){
        var total = this.POS.reduce((res,item)=>res+=item.refunds,0)
        return total
      },
      PERACCOUNTCLAIMED(){
        var total = this.claims.reduce((res,item)=>res+=item.claimed,0)
        return total
      },
      TOTALSALES(){
        var total = this.items.reduce((res,item)=>res+=item.sales,0)
        return total
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      commission(amount){
        let percentage = this.$IsNum(this.event.venue)/100
        return amount * percentage
      },
        profit(amount){
        return amount * .06
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.fight = data.fight
              this.Save()
          } else {
             this.getItems()
           
          }
      },
      getRefunds(player_id) {
        var refund = this.refunds.reduce((res,item)=>{
          if(item.refund_by == player_id) {
            res = item.refund
          }
          return res
        },0)
        return refund
      },
      formResEvent(){
        this.formform = false
        setTimeout(()=>{
                 this.getItems()
            }, 3000)
      },
       getFights(){
        this.claims = []
        this.items = []
          this.$http.post("event/report", {name: this.event.name}).then(response => {
            response.data.items !=null? this.items = response.data.items:this.items=[]  
            response.data.claims !=null? this.claims = response.data.claims:this.claims=[]
            response.data.refunds !=null? this.refunds = response.data.refunds:this.refunds=[] 
        }).catch(e => {
          console.log(e)
        })
      },
       getEvents(){
           this.$http.get("event/list").then(response => {
            response.data.items != null?this.events = response.data.items:this.events =[]
        }).catch(e => {
        console.log(e.data)
        })
      },
      
    }
  }
</script>